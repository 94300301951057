import React from 'react'
import { Link } from 'gatsby'
import { notfound, notfound_title, notfound_link} from "./NotFound.module.css"

const NotFound = () => {
    return (
        <div className={notfound}>
            <h1 className={notfound_title}>404: Not Found</h1>
            <Link to="/" className={notfound_link}>Return home</Link>
        </div>
    )
}

export default NotFound
